<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- <img class="banner" :src="this.url.imgUrl + banner" alt="" />
    <Notice></Notice> -->
    <!-- <div class="notice_box">
      <div class="notice_content">
        <img src="../../../assets/img/notice.png" alt="" />
        <marquee class="scroll" behavior="behavior" width="800" loop="2"
          >章节练习因定期调整和更新，题量会发生变化，试题总量，请考生以是几能抽出对题量为准。</marquee
        >
      </div>
    </div> -->
    <!-- 内容区 -->
    <div class="content_box">
      <div class="content">
        <!-- 导航 -->
        <div class="nav_box">
          <!-- 一级导航 -->
          <div class="one_box">
            <div>{{ one_name }}</div>
            <!-- <div class="select_option">
              <el-select v-model="value" placeholder="切换考试" @change="name">
                <el-option
                  v-for="item in one_nav"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
              <p>切换考试</p>
            </div> -->

            <div class="choose_btn" @click="dialogVisible = true">
              切换考试
              <img src="../../../assets/img/xuanze.png" alt="" />
            </div>
          </div>
          <!-- 二级导航 -->
          <div class="two_box">
            <div class="two_left">专业科目</div>
            <div class="two_right">
              <div
                class="major_el"
                :class="[choose_two == item.chapter_category_id ? 'major' : '']"
                v-for="item in two_nav"
                :key="item.chapter_category_id"
                @click="cutList(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <!-- <div class="menu_box">
          <img v-for="item in menu" :key="item.id" :src="item.icon" alt="" />
        </div> -->
        <Menu></Menu>
        <div class="list_box">
          <span>章节名称</span>
          <div class="list_el" v-for="item in chapters_list" :key="item.id">
            <div>{{ item.name }}</div>
            <button type="button" @click="goOption(item)">开始做题</button>
          </div>
        </div>
      </div>
    </div>
    <!-- dialog框 -->
    <el-dialog title="请选择专业科目" :visible.sync="dialogVisible" width="33%">
      <!-- <span>这是一段信息</span> -->
      <div class="chapter_category_box">
        <div
          class="chapter_category_el"
          v-for="item in one_nav"
          :key="item.id"
          @click="choose_major(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      banner: "/assets/img/infomation.jpg", //banner
      dialogVisible: false, //弹窗
      one_nav: [], //一级导航
      one_name: "", //一级导航名字
      two_nav: [], //二级导航
      two_name: "", //二级导航名字
      choose_two: "", // 二级导航默认选中
      chapters_list: [], //章节列表
      menu: [
        {
          id: 1,
          icon: require("../../../assets/img/lianxi.png"),
        },
        {
          id: 2,
          icon: require("../../../assets/img/yilian_normal.png"),
        },
        {
          id: 3,
          icon: require("../../../assets/img/zhenti_normal.png"),
        },
        {
          id: 4,
          icon: require("../../../assets/img/moni_normal.png"),
        },
        {
          id: 5,
          icon: require("../../../assets/img/jilu_normal.png"),
        },
        {
          id: 6,
          icon: require("../../../assets/img/cuoti_normal.png"),
        },
        {
          id: 7,
          icon: require("../../../assets/img/shoucang_normal.png"),
        },
      ], // 菜单
      value: [], //切换考试的专业
    };
  },
  created() {
    this.getOneChapter();
    let sto = this.$store.state.DbSource;
    // console.log("store", sto);
    // // console.log("store", this.$store.state.currDbSource);
    // let arr = ["0", "20", "0", "30", "10", "15"];
    let arr = [
      { name: "测试", num: 1, result: 0 },
      { name: "测试2", num: 2, result: 1 },
      { name: "测试3", num: 3, result: 0 },
      { name: "测试4", num: 4, result: 1 },
      {
        name: "测试5",
        // num: 5,
        qu_list: [
          { name: "测试6", num: 6, result: 0 },
          { name: "测试7", num: 7, result: 1 },
        ],
      },
    ];

    // function delArr(arr) {
    //   let index = 1;
    //   for (var i = 0; i < arr.length; i++) {
    //     if (arr[i].result == "1") {
    //       arr.splice(i, 1);
    //       i = i - 1;
    //       arr[i].num = index++;
    //       // console.log(arr[i]);
    //     }
    //     if (arr[i].qu_list) {
    //       for (var v = 0; v < arr[i].qu_list.length; v++) {
    //         // // console.log("v", arr[i].qu_list[v]);
    //         if (arr[i].qu_list.result == "0") {
    //           arr.splice(v, 1);
    //           v = v - 1;
    //           arr[i].qu_list.num = index++;
    //         }
    //       }
    //       // return arr[i].qu_list;
    //     }
    //   }
    //   return arr;
    // }
    // // console.log("数组===", delArr(arr));
  },
  methods: {
    getNickname() {
      // }
    },
    name() {
      this.one_name = this.value.name;
      // console.log("下拉", this.value);
      this.getTwoChapter(this.value.id);
    },
    //   获取一级章节分类
    getOneChapter() {
      api.chapter_category().then((res) => {
        // // console.log("一级分类", res);
        if (parseInt(res.data.code) == 1) {
          this.one_nav = res.data.data.list;

          if (sessionStorage.getItem("chapterName")) {
            this.one_name = sessionStorage.getItem("chapterName");
          } else {
            this.one_name = res.data.data.list[0].name;
          }
          if (sessionStorage.getItem("chapterId")) {
            this.getTwoChapter(sessionStorage.getItem("chapterId"));
          } else {
            this.getTwoChapter(res.data.data.list[0].id);
          }
        }
      });
    },
    // 点击一级导航切换章节
    choose_major(el) {
      // // console.log("章节", el);
      this.getTwoChapter(el.id);
      this.one_name = el.name;
      // 点击切换章节存储章节id
      sessionStorage.setItem("chapterId", el.id);
      sessionStorage.setItem("chapterName", el.name);
      this.dialogVisible = false;
    },
    //   获取二级章节分类
    getTwoChapter(id) {
      var data = {
        chapter_category: id,
        chapter_category_id: id,
      };
      api.chapter_list(data).then((res) => {
        // // console.log("二级分类", res);
        if (parseInt(res.data.code) == 1) {
          this.two_nav = res.data.data.list;
          // // console.log(this.two_nav);
          this.chapters_list = res.data.data.list[0].chapters;
          this.choose_two = this.two_nav[0].chapter_category_id;
          // // console.log(this.two_nav[0].chapter_category_id);
        }
      });
    },
    // 点击二级导航切换列表
    cutList(el) {
      // // console.log("二级导航点击", el);
      this.chapters_list = el.chapters;
      this.choose_two = el.chapter_category_id;
    },
    // 点击开始做题去答题页
    goOption(el) {
      // 开始做题之前先清除本地做题数据
      sessionStorage.removeItem("user");
      this.$router.push({
        name: "OptionTopic",
        params: { nav_id: 9, chapter_id: el.id },
        query: { name: el.name },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-scrollbar__wrap {
  width: 320px;
}
// banner
.banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

// 通知
.notice_box {
  width: 100%;
  height: 50px;
  background: #e9f2ff;
  margin-top: -3px;
  .notice_content {
    width: 1200px;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 16px;
      margin: 0 10px 0 20px;
    }
    .scroll {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e7eff;
    }
  }
}
// 内容区
.content_box {
  width: 100%;
  //   height: 1500px;
  background: #f5f5f5;
  overflow: hidden;
  .content {
    width: 1200px;
    margin: auto;
    //   导航
    .nav_box {
      width: 1160px;
      // height: 170px;
      padding:0 20px;
      background: #fff;
      border-radius: 6px;
      margin: 30px 0;
      overflow: hidden;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      //   一级导航
      .one_box {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        margin: 24px 0 26px;
        div {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }
        .select_option {
          position: relative;
          margin-left: 12px;
          /deep/.el-select {
            width: 96px;
            height: 26px;
            background: #3e7eff;
            border-radius: 13px;
            .el-input__inner {
              width: 96px;
              height: 26px;
              // color: red;
              color: rgba(0, 0, 0, 0);
              background: #3e7eff;
              border-radius: 13px;
              padding: 0;
              display: flex;
              justify-content: space-between;
              border: none;
              background-image: url("../../../assets/img/qiehuan.png");
              background-repeat: no-repeat;
              background-size: 13px 12px;
              background-position-x: 72px;
              background-position-y: 7px;
              // display: none;
            }
            .el-input__inner::placeholder {
              color: rgba(0, 0, 0, 0);
              padding-left: 11px;
            }
            .el-input__suffix-inner {
              display: none;
            }
          }

          p {
            position: absolute;
            top: 6px;
            left: 11px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #fff;
            // z-index: -1;
            // z-index: 999;
          }
        }
        .choose_btn {
          width: 81px;
          height: 23px;
          border: 1px solid #3e7eff;
          border-radius: 11px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3e7eff;
          line-height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          cursor: pointer;
          img {
            width: 12px;
            height: 8px;
            margin-left: 3px;
          }
        }
      }
      //   二级导航
      .two_box {
        width: 100%;
        // min-height: 65px;
        display: flex;
        .two_left {
          width: 60px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #484848;
          padding: 7px 0;
          // padding-top: 7px;
        }
        .two_right {
          width: 997px;
          margin-left: 49px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 18px;
          .major_el {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #202b3a;
            text-align: center;
            padding: 5px 19px;
            cursor: pointer;
          }
          .major {
            color: #3e7eff;
            // background: #3e7eff;
            border-radius: 18px;
            // max-height: 30px;
            // padding-bottom: 7px;
          }
        }
      }
    }
    //   菜单栏
    .menu_box {
      width: 100%;
      height: 130px;
      border-radius: 6px 6px 0 0;
      background: #f9fcff;
      display: flex;
      img {
        width: 170px;
        height: 130px;
      }
    }
    // 列表
    .list_box {
      width: 1160px;
      min-height: 610px;
      background: #fff;
      padding: 0 20px;
      margin-bottom: 125px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      span {
        height: 50px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        display: block;
        line-height: 50px;
      }
      .list_el {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f5f5f5;
        div {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #202b3a;
        }
        button {
          width: 100px;
          height: 34px;
          background: #3e7eff;
          border-radius: 17px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
.one_box::after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #f5f5f5;
  position: absolute;
  bottom: -14px;
  left: 0;
}
.chapter_category_box {
  width: 100%;
  display: flex;
  .chapter_category_el {
    cursor: pointer;
    margin-right: 30px;
  }
  .chapter_category_el:hover {
    color: #3e7eff;
  }
}
</style>
